import { Injectable } from '@angular/core';
import { CommonRepositoryAbstract } from '@freddy/common';
import { Asset } from '@freddy/models';
import { Store } from '@ngxs/store';
import { InGameState } from '../stores/in-game.store';
import { ORGANIZATION_PREFIX_PATH } from './config';
import { Firestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class AssetRepository extends CommonRepositoryAbstract<Asset> {
  static DOC_PATH = 'assets';

  constructor(
    firestore: Firestore,
    private readonly store: Store,
  ) {
    super(firestore);
  }

  public getOrganizationPrefixPath(): string {
    const org = this.store.selectSnapshot(InGameState?.currentOrganizationSlug);
    return org
      ? `${ORGANIZATION_PREFIX_PATH}/${this.store.selectSnapshot(InGameState?.currentOrganizationSlug)}/`
      : '';
  }

  protected getDocPath(): string {
    return this.getOrganizationPrefixPath() + AssetRepository.DOC_PATH;
  }
}
