export class LaunchMissionAction {
  static readonly type = '[Game] Launch mission';
  constructor(public readonly missionUid: string) {}
}

export class RestartApplicationCommandAction {
  static readonly type = '[Game] Restart application';
}

export class DisconnectPlayerCommandAction {
  static readonly type = '[Game] Disconnect player';
}
